import React from 'react'
// import socketio from "socket.io-client";
import { io as socketio } from "socket.io-client";

export let socket = null
export let initSocket = ({
  url,
  key,
}) => {

  socket = socketio.connect(url, {
    autoConnect: true,
    // autoConnect: false,
    query: "auth_token="+key,
    transports: [ "websocket" ],
  });

  return socket
}

export const SocketContext = React.createContext();
