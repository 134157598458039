import 'react-app-polyfill/ie11';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './components/App';
import { BrowserRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import './components/pages/chatscope_modifed.css'
import { SnackbarProvider } from 'notistack';
import { createStore } from 'redux'
import reducer from './reducers'
import { Provider } from 'react-redux'

// prepare store
const store = createStore(
  reducer,
)

const container = document.getElementById('root');

const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <SnackbarProvider maxSnack={3}>
        <App />
      </SnackbarProvider>
    </BrowserRouter>
  </Provider>
);
